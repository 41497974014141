<template>
  <b-modal id="modal-scrollable_question" scrollable title="回答分类" :hide-footer="true">
    <div class="accordion" role="tablist">
      <b-card no-body class="mb-1" v-for="cat in quickReplys" :key="cat.id">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="'accordion-' + cat.id" style="background: #2c3e50; border-color: #2c3e50">{{
            cat.name }}
            <span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span></b-button>
        </b-card-header>
        <b-collapse @show="getQuickReply(cat)" v-bind:id="'accordion-' + cat.id" accordion="my-accordion" role="tabpanel">
          <div v-for="i in cat.answers" :key="'ans' + i.id" style="padding: 2px 0px">
            <b-list-group-item @click="getQuickAnswers(i.message)">
              <p style="margin: 0px">{{ i.message }}</p>
            </b-list-group-item>
          </div>
        </b-collapse>
      </b-card>

      <!-- <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-2
            style="background: #2c3e50; border-color: #2c3e50"
            >提现<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>bbb</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-3
            style="background: #2c3e50; border-color: #2c3e50"
            >交易<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>ccc</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-4
            style="background: #2c3e50; border-color: #2c3e50"
            >Test<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>ccc</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button
            block
            v-b-toggle.accordion-5
            style="background: #2c3e50; border-color: #2c3e50"
            >注册<span class="when-opened" style="float: right">
              <b-icon icon="chevron-up" />
            </span>
            <span class="when-closed" style="float: right">
              <b-icon icon="chevron-down" /> </span
          ></b-button>
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>ccc</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card> -->
    </div>
  </b-modal>
</template>
      
<script>
import eventBus from "../assets/js/eventBus";
export default {
  name: "QuestionList",
  props: ["parentContext"],
  data() {
    return {
      categories: [],
      quickReplys: [],
    };
  },
  methods: {
    getQuickReply(val){
      console.log("VALLLL>>>",val)
      this.processAnswers(val.id)
      console.log("quickReplys arr>>",this.quickReplys);
      
    },
    loadCategories() {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/Chat/get_category`,
          method: "post",
          headers: {

          },
          data: {},
        })
        .then(async (res) => {
          let data = res.data;
          if (data.status == 200) {
            const categories = res.data.data;
            for (const category of categories) {
              that.quickReplys.push({ ...category, answers: [] })
              // await that.processAnswers(category.id);
            }
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    async processAnswers(categoryId) {
      let that = this;
      that
        .$http({
          url: `${that.$baseURL}app/Chat/quick_reply`,
          method: "post",
          headers: {

          },
          data: {
            category: categoryId
          },
        })
        .then((res) => {
          if(res.data.status == 200){
            const categoryIndex = that.quickReplys.findIndex(element => element.id == categoryId);
            if(categoryIndex != -1){
              that.quickReplys[categoryIndex].answers = res.data.data;
            }
          }else if(res.data.status == 401){
            eventBus.$emit("logout");
            this.$router.push({ name: "Login" });
          }
        }).catch((e)=>{
          console.error(e.message);
        });
    },
    loadCategoryAnswers(catId) {
      let that = this;
      that
        .$http({
          url: "api/answer/list/" + catId,
          method: "post",
          headers: {
            "access-token": that.$store.state.token,
          },
          data: {},
        })
        .then((res) => {
          let data = res.data;
          if (data.code == 200) {
            let obj = {
              catId: catId,
              answers: data.data,
            };
            that.$store.commit("setAnswers", obj);
          }
        });
    },
    loadAnswers() {
      let that = this;
      that.$store.state.quick_answers.forEach((element) => {
        that.loadCategoryAnswers(element.id);
      });
    },
    getQuickAnswers(msg) {
      // this.parentContext.inputMessage = msg;
      this.$emit('onQuickReplySelected',msg)
      this.$bvModal.hide("modal-scrollable_question");
    },
  },
  mounted: function () {
    this.loadCategories();
  },
};
</script>
      
<style>
.collapsed>.when-opened,
:not(.collapsed)>.when-closed {
  display: none;
}
</style>